<template>
  <v-dialog v-model="open" persistent width="400">
    <v-card>
      <v-card-title>提醒</v-card-title>
      <v-card-text class="text-body-1">
        <!-- <v-alert border="top" colored-border type="error" elevation="1"> -->
        請購單已校正完成，請到
        <a href="/dashboard/stock" target="_blank">庫存頁面(另開視窗)</a>
        進行相關店點的庫存校正。
        <!-- </v-alert> -->
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text class="bt-text-large" color="primary" @click="open = false">
          確認
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";

export default {
  name: "DialogStockRegulate",
  props: ["value", "payload"],
  data: () => ({
    fixShipped: null,
    fixReceived: null,
  }),
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {},
};
</script>

<style></style>
