<template>
  <v-container fluid>
    <h3 class="page-title">歷史採購單</h3>
    <v-row align="center">
      <v-col cols="9" md="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formattedDate"
              label="日期區間"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            range
            no-title
            scrollable
            locale="zh-tw"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> 取消 </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              確定
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3" sm="auto" md="1">
        <v-btn color="primary" @click="search">
          <span class="d-none d-md-inline">查詢</span>
          <v-icon class="d-md-none">mdi-magnify</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h4>篩選條件</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3" md="2">
        <v-text-field
          label="關鍵字"
          placeholder="關鍵字(商品名稱、備註)"
          v-model.trim="filterQuery.keyword"
          outlined
          dense
          hide-details
          clearable
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" sm="3" md="2">
        <v-autocomplete
          label="收貨倉"
          v-model="filterQuery.to"
          outlined
          dense
          hide-details
          :items="warehouseUnitOptions"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="6" sm="3" md="2">
        <v-select
          label="狀態"
          v-model="filterQuery.status"
          outlined
          dense
          hide-details
          :clearable="$vuetify.breakpoint.smAndUp"
          :items="[
            { text: '全選', value: '' },
            { text: '完成', value: 'finished' },
            { text: '作廢', value: 'canceled' },
            { text: '處理中', value: 'progress' },
          ]"
        >
        </v-select>
      </v-col>
      <v-col
        cols="auto"
        class="d-none d-sm-block pa-0 ml-auto"
        align-self="center"
        order-md="last"
      >
        <v-btn
          color="primary"
          class=""
          @click="exportExcel"
          :disabled="!filteredResult || filteredResult.length === 0"
          >匯出
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="result">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="filteredResult"
          :mobile-breakpoint="0"
          item-key="_id"
          show-expand
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [10, 30, -1],
          }"
        >
          <template v-slot:item.from="{ item }">
            {{ "外部採購" }}
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip
              v-if="item.status === 'progress'"
              class="ma-2"
              color="green"
              text-color="white"
              x-small
            >
              處理中
            </v-chip>
            <v-chip v-if="item.status === 'finished'" class="ma-2" x-small>
              已完成
            </v-chip>
            <v-chip
              v-if="item.status === 'canceled'"
              class="ma-2"
              color="error"
              x-small
              outlined
            >
              已作廢
            </v-chip>
          </template>
          <template v-slot:item.items="{ item }">
            <div v-for="(transaction, index) in item.items" :key="index">
              <div class="my-2">
                {{ transaction.product.name }}：
                {{ formatPrice(transaction.required) }}
              </div>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <div>
              <v-btn
                v-if="item.status !== 'canceled'"
                @click="remove(item)"
                class="pointer"
                color="error"
                small
                text
              >
                作廢 X
              </v-btn>
            </div>
            <div>
              <v-btn @click="renewSum(item)" color="primary" small text>
                刷新＄
              </v-btn>
            </div>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-4">
              <v-row>
                <v-col cols="12" md="6">
                  <h4 class="px-4 py-4">採購商品明細</h4>
                  <v-data-table
                    :headers="expandedHeaders"
                    :items="item.items"
                    :mobile-breakpoint="0"
                    hide-default-footer
                    :style="{ width: 'max-content' }"
                  >
                    <template v-slot:item.required="{ item }">
                      {{ formatPrice(item.required) }}
                    </template>
                    <template v-slot:item.shipped="{ item }">
                      {{ formatPrice(item.shipped) }}
                    </template>
                    <template v-slot:item.received="{ item }">
                      {{ formatPrice(item.received) }}
                    </template>
                    <template v-slot:item.amount="{ item }">
                      {{ formatPrice(item.amount) }}
                    </template>
                  </v-data-table>
                  <div class="pa-2">
                    <div class="font-weight-bold black--text">
                      總金額：
                      {{ formatPrice(item.sum) }}
                    </div>
                    <p class="mb-0 caption">＊以實際到貨商品進行計算</p>
                  </div>
                </v-col>
                <v-col cols="12" md="6" v-if="item.comment">
                  <h4 class="px-4 py-4">備註</h4>
                  <v-textarea
                    :value="item.comment"
                    rows="2"
                    row-height="25"
                    auto-grow
                    hide-details
                    readonly
                    class="px-4"
                  ></v-textarea>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogReminder v-if="reminder" v-model="reminder" />
  </v-container>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";
import util from "@/mixins/util";
import { warehouseUnitOptions } from "@/config/common.js";
import DialogReminder from "@/components/dashboard/purchase/DialogReminder.vue";

export default {
  name: "PurchaseHistory",
  mixins: [util],
  components: { DialogReminder },
  data: () => ({
    date: [],
    menu: false,
    reminder: false,
    result: null,
    branch: null,
    products: [],
    filterQuery: {
      to: "",
      from: "",
      status: null,
      keyword: "",
    },
    statusMap: {
      finished: "完成",
      canceled: "作廢",
      progress: "處理中",
    },
    warehouseUnitOptions: [
      { text: "全選", value: "" },
      ...warehouseUnitOptions,
    ],
    headers: [
      { text: "出貨倉", value: "from", sortable: false },
      { text: "收貨倉", value: "to", sortable: false },
      { text: "請購商品", value: "items", sortable: false },
      { text: "狀態", value: "status", sortable: false, align: "center" },
      { text: "總金額", value: "sum", align: "right", sortable: false },
      { text: "建立時間", value: "createdAt" },
      { text: "更新時間", value: "updatedAt" },
      { text: "操作", value: "actions", align: "center", sortable: false },
    ],
    expandedHeaders: [
      { text: "商品名稱", value: "product.name", sortable: false },
      { text: "數量", value: "required", sortable: false, align: "right" },
      // { text: "已出貨", value: "shipped", sortable: false, align: "right" },
      { text: "已收貨", value: "received", sortable: false, align: "right" },
      { text: "金額", value: "amount", sortable: false, align: "right" },
    ],
  }),
  async created() {
    // await this.load();
    this.date[0] = dayjs().format("YYYY-MM-01");
    this.date[1] = dayjs().endOf("month").format("YYYY-MM-DD");
  },
  computed: {
    formattedDate() {
      return this.date[0] && this.date[1]
        ? dayjs(this.date[0]).format("YYYY-MM-DD") +
            " ~ " +
            dayjs(this.date[1]).format("YYYY-MM-DD")
        : "";
    },
    filteredResult() {
      if (!this.result) return null;

      let result = [...this.result];
      const { to, from, status, keyword } = this.filterQuery;

      // 外部採購的 value 值為 null
      if (to === null || to) {
        result = result.filter((row) => row.to === to);
      }
      if (from === null || from) {
        result = result.filter((row) => row.from === from);
      }
      if (status) {
        result = result.filter((row) => row.status === status);
      }
      if (keyword) {
        result = result.filter(({ comment, items }) => {
          const hasMatchProduct = items.some((item) =>
            item.product.name.toUpperCase().includes(keyword.toUpperCase())
          );
          return (
            hasMatchProduct ||
            comment?.toUpperCase().includes(keyword.toUpperCase())
          );
        });
      }
      return result;
    },
    unit() {
      if (!this.result) return;

      let to = _.uniq(this.result.map((row) => row.to));
      if (to.length > 0) {
        to = [{ text: "全選", value: "" }, ...to];
      }

      let from = _.uniq(this.result.map((row) => row.from));
      if (from.length > 0) {
        from = [
          { text: "全選", value: "" },
          // ...(this.comment && { comment: this.comment }),
          ...from,
          { text: "外部採購", value: null },
        ];
      }

      return { to, from };
    },
  },
  methods: {
    async load() {
      await this.search();
    },
    async search() {
      if (!this.date) {
        return false;
      }

      this.$vloading.show();
      try {
        let { data } = await this.axios.get(`/purchase/updatedAt`, {
          params: {
            dateRange: this.date,
          },
        });
        this.result = data
          .filter((item) => item.from === null)
          .sort((a, b) => {
            const aTime = a.createdAt;
            const bTime = b.createdAt;
            if (dayjs(aTime).isAfter(dayjs(bTime))) {
              return -1;
            }
            if (dayjs(aTime).isBefore(dayjs(bTime))) {
              return 1;
            }
            return 0;
          });
      } catch (error) {
        this.$toast.error(error.message);
      }

      this.$vloading.hide();
    },
    async remove(purchase) {
      if (!purchase) return;
      if (
        !confirm(
          `確定作廢此請購單? 提醒請購單作廢後，需進行相關店點的庫存校正。`
        )
      )
        return;

      try {
        await this.axios.put(`/purchase/${purchase._id}/cancel`);
        this.reminder = true;
        this.$toast.success("更新成功");
        this.load();
      } catch (error) {
        this.$toast.error(error.response.data?.message);
      }
    },
    async renewSum(payload) {
      try {
        this.$vloading.show();
        await this.axios.put(`/purchase/${payload._id}/update-sum`);

        this.$toast.success("刷新成功");
        await this.load();
      } catch (error) {
        console.log(error);
      }
      this.$vloading.hide();
    },
    async exportExcel() {
      if (Object.values(this.filterQuery).some((val) => !!val)) {
        const confirm = await this.$dialog.confirm({
          text: `未清除篩選條件，匯出資料非完整資料，是否確認印出？`,
        });
        if (!confirm) return;
      }
      // 需求：收貨日期、出貨倉、收貨倉、請購品項、單價、請購數量、總金額、備註
      const branch = ["文賢", "崇善", "奇美", "總倉"];
      const sheets = branch.reduce((result, branch) => {
        // 1 進貨表
        const inPurchase = this.formatResult(
          this.filteredResult.filter((item) => item.to === branch)
        );
        // 2 出貨表
        // const outPurchase = this.formatResult(
        //   this.filteredResult.filter((item) => item.from === branch)
        // );
        return [
          ...result,
          { data: inPurchase, sheetName: `${branch}採購` },
          // { data: outPurchase, sheetName: `${branch}出貨` },
        ];
      }, []);
      this.exportToExcelSheets(sheets, "歷史採購單");
    },
    formatResult(result) {
      const ignoreKeys = ["_id", "items"];
      const translatedHeader = {
        from: "出貨倉",
        to: "收貨倉",
        item: "請購品項",
        price: "單價",
        required: "請購數量",
        amount: "金額",
        sum: "總金額",
        status: "狀態",
        comment: "備註",
        createdAt: "建立日期",
        updatedAt: "更新日期",
      };

      const flattenItemsResult = [];
      result.forEach((row) => {
        row.items.forEach((item, index) => {
          const itemInfo = {
            item: item.product.name,
            required: item.required,
            amount: item.amount,
            price: item.product.price || "",
          };
          if (index === 0) {
            flattenItemsResult.push({ ...row, ...itemInfo });
            return;
          }
          flattenItemsResult.push(itemInfo);
        });
      });

      const printResult = flattenItemsResult
        .map((transaction) => _.omit(transaction, ignoreKeys))
        .map((transaction) => {
          return {
            ...transaction,
            from: transaction.from === null ? "外部採購" : transaction.from,
            status: this.statusMap[transaction.status],
          };
        })
        .map((transaction) => {
          // 鍵的順序
          const desiredOrder = Object.keys(translatedHeader);
          // 取出物件的所有鍵並進行排序
          const sortedKeys = Object.keys(transaction).sort((a, b) => {
            return desiredOrder.indexOf(a) - desiredOrder.indexOf(b);
          });

          // 構造新的物件，根據排序後的鍵來取出值
          const sortedObject = {};
          sortedKeys.forEach((key) => {
            sortedObject[key] = transaction[key];
          });
          return sortedObject;
        })
        .map((transaction) => {
          return Object.fromEntries(
            Object.entries(transaction).map(([key, value]) => [
              translatedHeader[key] || key,
              value,
            ])
          );
        });

      return printResult;
    },
  },
};
</script>

<style lang="scss" scoped></style>
